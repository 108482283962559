<template>
  <div class="appointmentList-view">
    <el-form
        ref="ruleForm"
        class="ruleForm"
        label-width="100px"
      >
      <el-form-item label="姓名">
        <div class="flex-a-c">
          
          <el-select
            v-model="formdata.empId"
            :filterable="true"
            :disabled="type == 'edit' ? true : false"
            style="width: 240px;margin-bottom:20px"
            placeholder="请选择"
          >
            <el-option
              v-for="(item,index) in memberList"
              :key="index"
              :label="item.name"
              :value="item.empId"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="负责部门">
        <div class="flex-a-c">
          <el-select
            v-model="formdata.departmentId"
            style="width: 240px;"
            placeholder="请选择"
          >
            <el-option
              v-for="(item,index) in departmentList"
              :key="index"
              :label="item.name"
              :value="item.departmentId"
            />
          </el-select>
          <div class="flex-a-c ml20" style="color: #8d97ad; font-size: 14px" v-if="departmentList.length == 0">
            暂无部门
            <el-button
                class="v-primary ml10"
                type="primary"
                @click="toSectorSetupList"
                >去设置</el-button>

          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-switch
          v-model="formdata.empStatus">
        </el-switch>

      </el-form-item> -->
      <el-form-item label="权限">
        <el-tree :data="treeData" 
                  ref="tree"
                  :props="defaultProps" 
                  show-checkbox
                  accordion
                  @node-click="handleNodeClick"
                  @check="currentChange"
                  node-key="id"></el-tree>
      </el-form-item>
      <el-form-item>
        <p class="ml10">未选择任何权限即默认此管理员为普通员工权限</p>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="saveManager"
          >保存</el-button
        >
      </el-form-item>
   </el-form>
  
  </div>
</template>

<script>
import commonTree from "@/components/common/commonTree";
import {mapMutations} from 'vuex'
import {
  queryPageEmployee,
  queryPageDepartment,
  addManager,
  queryEmployee,
  updateManager
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import userRoot from '@/utils/root'

export default {
  name: "appointmentList",
  components:{
    
  },
  data() {
    return {
      keyword:'',
      optionList: "", //全部分类
      tableData: [],
      staffList:[],
      loading: false, //加载中
      memberName:'', //管理员名字
      department:'', //部门名字
      memberList:[],
      departmentList:[],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      formdata:{
        empId:'',
        departmentId:'',
        empStatus:false,
        routers:['022','0213','04','040','041','08'],
        userType:1  //用户类型 0 默认员工 1管理员 2 boss
      },
      type:'add'
    };
  },
  
  mounted() {
    this.getTableData()
    this.getPageDepartment()
    
    console.log(this.type)
    this.treeData = userRoot.userRoot
    let userInfo = JSON.parse(localStorage.getItem('info'));
    if(this.$route.query.id){
      this.type = 'edit'
      this.queryEmployee()
    }
    this.$refs.tree.setCheckedKeys(this.formdata.routers)
  },
  methods: {
    ...mapMutations(['setRouterArr']),
    //获取企业成员
    getTableData() {
      let data = {
        pageSize: 999,
      };
      queryPageEmployee(data)
      .then((res) => {
        this.memberList = res.data.pageInfo.list || "";
        //console.log(this.memberList)
      })
      .catch((err) => {
        this.tableData = [];
        //console.log(err);
      });
    },
    //查询成员列表
    async getCompanyList() {
      let result = await queryPageEmployee({ pageSize:10 })
      //console.log('result',result)
      this.staffList = result.data.list
      this.staffList.forEach(v => {
        v.isSelect = false
      });
    },

    //请求部门信息
    getPageDepartment() {
      this.loading = true;
      let data = {
        pageSize: 999,
      };
      queryPageDepartment(data)
      .then((res) => {
        this.departmentList = res.data.pageInfo.list || "";
        
      })
      .catch((err) => {
        this.departmentList = [];
      });
    },
    //查看所有
    checkAll(lsitData){
      this.checkVisible = true
      this.memberData = lsitData
    },
    //解散部门
    dissolve(){
      this.$confirm("是否确认解散该分组，解散后不可恢复。", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        
        
      })
      .catch((err) => {});
    },
    //保存管理员
    async saveManager() {
      if(!this.formdata.empId){
        this.$message.error('请确认选择管理员')
      }
      let result = ''
      if(this.type == 'add'){
        result = await  addManager(this.formdata)
      }else{
        result = await  updateManager(this.formdata)
      }
      //console.log(result)
      if(result.code == 200 && this.type == 'add'){
        this.$message.success('保存成功')
        this.$router.go(-1)
      }else{
        this.$message.success('修改成功')
        this.$router.go(-1)
      }
    },
    //编辑成员
    editDepartment(item){
      this.editVisible = true
      this.staffList = []
      item.forEach(v => {
        this.staffList.push({
          name:v,
          isSelect:false
        })
      });
    },
    
    //查询管理员详情
    queryEmployee(type){
      let data = {
        empId:this.$route.query.id
      }
      queryEmployee(data)
      .then(res=>{
        if(type !== 'add' ){
          this.formdata.empId = res.data.empId
          this.formdata.departmentId = res.data.departmentId
          this.formdata.empStatus = res.data.empStatus
          if(res.data.routers 
              && res.data.routers !== 'null' 
              && res.data.routers !== '[\"022\",\"0213\",\"04\",\"040\",\"041\",\"08\"]'){
            this.formdata.routers = JSON.parse(res.data.routers)
            this.formdata.routers =  this.filtration(this.treeData)
          }else{
            this.formdata.routers = ['022','0213','04','040','041','08']
          }
          this.formdata.userType = res.data.userType
          
          this.$refs.tree.setCheckedKeys(this.formdata.routers)
        }else{
          let arr = JSON.parse(res.data.routers)
          this.setRouterArr(arr)
          
        }
        
      })
    },
    handleNodeClick(data){
      //console.log(data)
    },
    currentChange(a,b){
      console.log(b);
      this.formdata.routers = b.checkedKeys.concat(...b.halfCheckedKeys)
      console.log(this.formdata.routers);
    },
     filtration(treeData){
       console.log(this.formdata.routers)
      for (let index = 0; index < treeData.length; index++) {
         if(treeData[index].children){
           console.log(JSON.parse(JSON.stringify(treeData[index])),'treeData[index]');
           if(this.formdata.routers.findIndex(item => item == treeData[index].id) !== -1){
             this.formdata.routers.splice(this.formdata.routers.findIndex(item => item == treeData[index].id),1)
           }
           this.filtration(treeData[index].children)
         }
      }
      return this.formdata.routers
    },
    toSectorSetupList(){
      this.$router.push('/sectorSetup')
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.member-box{
  padding: 5px 10px;
  background: #51CDCB;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
}
.more-box{
  padding: 5px 10px;
  background: #FD634E;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  border-radius: 3px;
}
.member-scroll{
  max-height: 300px;
  overflow-y: auto;
}
</style>